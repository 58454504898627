<template>
  <div class="login-wrap">
    <div class="login-logo-wrap">
      <img src="@/assets/img/astellas_logo.svg" alt="" />
    </div>
    <a-form class="login-form" @submit.prevent="submitForm">
      <a-form-item>
        <a-input class="text-input" type="text" v-model="userId" size="large" placeholder="Username" ref="refEmail">
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          class="text-input"
          type="password"
          v-model="password"
          size="large"
          placeholder="Password"
          ref="refPassword"
        ></a-input>
      </a-form-item>
      <!-- !isUsernameValid ||  -->
      <a-button
        type="primary"
        html-type="submit"
        class="login-form-button"
        :disabled="!userId || !password"
        :class="!password ? 'disabled' : null"
      >
        Log in
      </a-button>
      <div class="hgap"></div>
      <!-- <a-button type="primary" class="login-form-button" @click="gotoRegist"> 회원가입 </a-button> -->
    </a-form>
    <!-- <p class="log">{{ logMessage }}</p> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      userId: 'user9@predict.kr',
      password: 'user9',
    };
  },
  created() {},
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  methods: {
    async submitForm() {
      // 필수체크
      this.signIn(this).then((status) => {
        // if (status !== 200) {
        //   alert('아이디 또는 비밀번호를 확인해 주세요');
        //   return;
        // }
        this.$router.push('/home').catch(() => {});
      });
    },
    ...mapActions(['signIn']),
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.login-wrap {
  background: #fff;
  padding: 40px 80px 100px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  .login-logo-wrap {
    padding: 40px;
    text-align: center;
    img {
      height: 60px;
      margin-left: -23px;
    }
  }
  .login-form {
    width: 350px;
  }
  .login-form-button {
    background: $primary-color;
    border-color: $primary-color;
    color: #ffffff;
    border-radius: 0;
    height: 50px;
    width: 100%;
  }
}
</style>
